import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-baby-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1973 shocker, The Baby"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 16</h1>
                    <h2>The Baby</h2>
                    <h3>September 4, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/the-baby">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:01.792)
You&#39;re listening to Bring Me the Axe. I&#39;m Brian White, one half of this morbid equation, and I&#39;m joined by my cohost and actual brother, Dave White. Dave, how you doing?</p>
<p>Dave! (00:09.806)
Well, I&#39;m okay. There are a lot of children outside of my house screaming. They&#39;re not technically they&#39;re not outside of my house screaming like at my house. They&#39;re just in the vicinity of my apartment and they&#39;re just shouting at my house.</p>
<p>Bryan! (00:22.696)
It&#39;d be really weird if there was like a dozen children just pointing at your house and screaming. I think you&#39;d have a real problem. Yeah, I got some kids outside of my house also screaming at the house, but they&#39;re actually screaming at the house. No. No, oh, God. No, my kids don&#39;t go outside. Are you crazy? Yeah, I should do that. Also, it&#39;s hot as fucking balls, man. I thought we were past this.</p>
<p>Dave! (00:31.467)
Couple. Couple, actually.</p>
<p>Dave! (00:39.509)
Do they belong to you though? Go throw rocks at them.</p>
<p>Dave! (00:49.374)
Mmm</p>
<p>Bryan! (00:52.256)
Yeah, anyways. So we practically grew up in neighborhood video stores and the steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else that I enjoy chopping it up with more about trashy movies than Dave. Just before we get into it, here&#39;s a little housekeeping. If you wanna keep up with us between episodes, you can also find us pretty much on all the socials by searching Bring Me the Axe Horror Podcast. We&#39;ve also got a sweet website at bringmediax.com. You can listen to all our past shows there and read the transcripts.</p>
<p>You can also contact us directly at bring me the ax pod at gmail.com with any questions comments or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts and you&#39;d be doing us a favor by leaving a five star review on Apple podcasts. So god damn it. Get to it, please. It&#39;s the you know what? You know what? It&#39;s it&#39;s a thing.</p>
<p>Dave! (01:41.621)
It&#39;s not hard. You just touch the thing. It&#39;s a screen. You touch your screens now.</p>
<p>You just didn&#39;t have to do anything.</p>
<p>Bryan! (01:46.472)
It seems way more complicated than it is. When you think about it, like it&#39;s this, but it&#39;s literally you go review, and then it says how many stars, and you push five, and then you push submit, and then that&#39;s it, it&#39;s awesome. So just want to get that all out of the way right at the top of the show. So this kicks off our What the Fuck series, a series that I hope will come back to in the future, because this is going to be a lot of fun. For this series, we both picked a horror movie that very easily evades a description. And...</p>
<p>They&#39;re movies that are so unusual that it&#39;s otherwise very hard to explain to someone why they might take the time to watch this movie. And we&#39;re kicking it off with my pick. So let me give you a taste of what&#39;s coming.</p>
<p>Bryan! (02:35.708)
I noticed you call him Baby. And the case history doesn&#39;t show any other name. What is his real name? Just Baby. True Baby, life was not a giant playpen. It was a living hell. He wasn&#39;t allowed to walk, he wasn&#39;t allowed to talk, but he was capable of it. Baby is a full grown man, trapped by three women, with no way out. Mommy, no. Damn you.</p>
<p>Dave! (05:13.649)
Oh boy.</p>
<p>Bryan! (06:15.065)
Awesome. Yeah, my window crashed as I was recording that.</p>
<p>Dave! (06:18.712)
Well, that&#39;s the price you pay for that movie. All right.</p>
<p>Bryan! (06:20.136)
Yep. So. Yeah. So here we go. We&#39;re just going to just kind of keep going like nothing happened. Yep. Yeah. So there it is. The baby. This is this is one I&#39;ve been looking forward to for a long time. Like we planned that we&#39;ve had this one planned for quite some time. And I the time is finally here. This is one of my this is I would say this is one of my favorite movies. Dude, my.</p>
<p>Dave! (06:46.785)
How is that possible? I have seen this movie more than once and I get liking it, but sure...</p>
<p>Bryan! (06:53.668)
Yeah, my favorite, well also like my favorite movie is also Escape from New York and like</p>
<p>Dave! (06:57.678)
And that I understand because that is a fantastic film.</p>
<p>Bryan! (07:00.328)
Yeah, but here&#39;s the thing is, as I once met Adrienne Barbeau and I told her that and she said I was crazy for that. So I kind of I kind of get it, you know. Yep, I god damn it. So I just this is just it&#39;s so fucking good. I don&#39;t really know how else to explain it.</p>
<p>Dave! (07:17.57)
He&#39;s just, he&#39;s, it&#39;s a baby and he&#39;s trapped by three women. Hey, do you think this was written by a man?</p>
<p>Bryan! (07:23.546)
trapped in a world he never made. So yeah, caution. As usual, we&#39;re going to spoil the shit out of this one, but I very strongly recommend watching the movie first and then coming back to this. Usually, I couldn&#39;t care less, but this is a movie that is so unusual and so full of crazy plot twists that I really hate to be the one that ruins it for you. It&#39;s currently streaming on Tubi, as all good movies are.</p>
<p>Uh-huh. And as of that&#39;s as of this recording and Arrow has a really nice blu-ray of it. That&#39;s a reissue of the old Severin DVD And they&#39;re so you got plenty of options to watch it. So Do that come back or else, you know, you&#39;re taking your life into your own</p>
<p>Dave! (08:04.987)
I&#39;d at least watch the trailer, because Jesus Christ. The trailer&#39;s like, here, here&#39;s the entire fucking movie. You don&#39;t need any context because you&#39;re still going to have questions at the end anyway.</p>
<p>Bryan! (08:08.18)
The trailer, you know, here&#39;s the thing with the trailer. The trailer.</p>
<p>Bryan! (08:15.716)
The trailer really does give away some very vital plot twists. Thankfully, I saw the trailer after I saw the movie. So, you know, like when this movie kind of unfolds and reveals itself, I was just so fucking delighted. So here&#39;s some facts. The year was 1973.</p>
<p>Dave! (08:37.794)
1973.</p>
<p>Bryan! (08:39.136)
Yeah. Year of our lord. So some other movies that were released that year Messiah of Evil, which also stars is her name Mariana Hill. Mariana Hill. She plays Jermaine. The Wicker Man also came out this year. The Iron Rose, which is a really, really nice Jean Rolland movie that is that&#39;s true. This is this one is definitely I very greatly enjoy it.</p>
<p>Dave! (08:48.734)
Carry on a hell of a hill.</p>
<p>Dave! (09:01.216)
There&#39;s not a lot of those.</p>
<p>Bryan! (09:08.132)
I don&#39;t know if I&#39;d say that I like his movies. There&#39;s definitely something to them, but I find them very hard to watch. Lot of sexy vampires. No sexy vamp. Oh yeah, yeah. No sexy vampires in this one. This is a whole other thing. Also, Flesh for Frankenstein came out in 1973. Yep. And you know what else came out in 1973? The Exorcist. Yep.</p>
<p>Dave! (09:15.07)
Let us sexy vampires. Let us sexy everything in his movies.</p>
<p>Dave! (09:28.619)
Also sexy.</p>
<p>Dave! (09:35.094)
Yeah. Mm. I don&#39;t be exorcist. I- I can take it or leave it.</p>
<p>Bryan! (09:37.3)
Banner year, I fucking... What are you making those noises for?</p>
<p>Bryan! (09:43.5)
God, it&#39;s a good movie, man. So cast and crew, the director is a man named Ted Post, and he is a pure utility director. He worked in film and television, kind of in equal measure. Like a lot of the guys that we tend to cover on this, they kind of go either or, like they were all big, you know, theatrical guys, or they were TV guys who did the occasional theatrical movie.</p>
<p>Dave! (10:11.195)
I would say any movie pre-1975, 1976, when you start to get the auteur, the idea of the auteur, because before that it&#39;s just all, you did have some auteurs, you had your Hitchcocks and the like, but it&#39;s mostly a lot of just like journeymen.</p>
<p>Bryan! (10:19.465)
Yeah, yeah.</p>
<p>Dave! (10:28.61)
filmmakers who just, it&#39;s a fucking job.</p>
<p>Bryan! (10:31.388)
Yeah, yeah, this, yeah, Post is definitely, yep, Ted Post.</p>
<p>Dave! (10:33.158)
Exam, case in point, this movie. Because this man is a very capable director.</p>
<p>Bryan! (10:38.632)
He is. He so he&#39;s probably best known for his work on Rawhide, which is which is what put him in touch with Clint Eastwood. He also did some episodes of Peyton Place and The Twilight Zone.</p>
<p>Dave! (10:51.662)
He did like a shit, he did like 140 episodes of Pain in Place. Which is like the entire series.</p>
<p>Bryan! (10:55.282)
Yeah.</p>
<p>Bryan! (10:58.812)
Yeah, yeah. So, theatrically, however, he&#39;s the man behind Beneath the Planet of the Apes, which Not my favorite of the series, but it&#39;s up there And I would put it neck and neck with the original</p>
<p>Dave! (11:10.606)
Is that the last? Is that the one with Ricardo Montalban? God damn it.</p>
<p>Bryan! (11:13.268)
No, no. So, so beneath the play, I know we just went over this. No, no, I believe model bond is in battle for the planet of the apes. Yeah, I&#39;m not sure which one is, but no, beneath the planet of the apes is the one where is this is the direct sequel to planet of the apes. And that also like this movie has a fucking crazy twist ending. You&#39;ve seen it, right? That&#39;s the one with the bomb.</p>
<p>Dave! (11:17.273)
I&#39;m running out of options here. Process of elimination, I&#39;ll get there. I know it&#39;s not the first one.</p>
<p>Dave! (11:25.194)
He&#39;s in the last one. That&#39;s all I know. That&#39;s all I remember.</p>
<p>Bryan! (11:42.312)
and the mutants underneath. Yeah.</p>
<p>Dave! (11:42.73)
Yes. That&#39;s, I thought that was the last one.</p>
<p>Bryan! (11:45.832)
No, it&#39;s just the second one. There&#39;s like a hundred Planet of the Apes movies. It seems like there&#39;s a hundred. There&#39;s also a TV show. Yeah, he also worked on the Dirty Harry sequel, Magnum Force. And also, I don&#39;t think I&#39;ve ever seen it, but I&#39;m not also, I don&#39;t really give a fuck about Dirty Harry.</p>
<p>Dave! (11:48.526)
There&#39;s five of them.</p>
<p>Dave! (11:59.671)
Terrible movie.</p>
<p>Dave! (12:05.314)
Yeah, think about Clint Eastwood. He can get fucked. That guy sucks.</p>
<p>Bryan! (12:08.04)
Yeah, I could suck at Clintus Wood. But he also.</p>
<p>Dave! (12:12.79)
That&#39;s my- that&#39;s our official position on Clint Eastwood. Fuck that guy.</p>
<p>Bryan! (12:16.324)
Yeah, yeah, you know, he I&#39;m not going to bring up the chair. Also, I mean, speaking of, you know, fuck this actor, he was also responsible for the breakthrough Chuck Norris picture. Good guys wear black. Yeah, not a bad movie, but yeah, it means having to sit through a Chuck Norris movie.</p>
<p>Dave! (12:29.573)
Ehh...</p>
<p>Bryan! (12:39.692)
The cast, Anjanette Comer is the lead, and she is an actress primarily known for her work in guest spots on TV shows. They all have titles like Troy McClure movies, like Banyan, Banning, and Vencaine Bronson.</p>
<p>Dave! (12:55.117)
Yeah, shit that was on for like a season, like a mid-season replacement. She was in Dead of Night though.</p>
<p>Bryan! (13:00.168)
Yes, you know what else she was in? Renee Cardona&#39;s Night of a Thousand Cats.</p>
<p>Dave! (13:04.714)
Oh, that one is hard. That is a hard. I am a big Rene Cardona fan, even though he did kill animals in his movies. I am still a big fan of his. And yet that one is real tough.</p>
<p>Bryan! (13:06.888)
Hahaha</p>
<p>Bryan! (13:16.82)
Yeah, how many cats do you think you killed in Night of a Thousand Cats? Probably all one thousand of them. Yeah, we also feature Ruth Roman here, who not exactly like a big name leading lady. She&#39;s kind of a model, like a minor league type.</p>
<p>Dave! (13:19.254)
Probably all of them.</p>
<p>Thanks for watching!</p>
<p>Dave! (13:34.22)
She came late, I think, in this, like, the very end of the studio system. She was kind of a replacement for like Joan Crawford, because she looks like Joan Crawford.</p>
<p>Bryan! (13:38.589)
Yes, she she.</p>
<p>She does. Actually, I confuse her very easily with Russell and Russell from Gypsy. Like they both look kind of the same, but also I can see why they would have brought her in for Joan Crawford, because she does.</p>
<p>Dave! (13:54.782)
this was I think she was at Warner Brothers and it was it was like right after Joan Crawford&#39;s like career kind of tanked and she got fired from I think I think it was Warner Brothers but she was brought in kind of to I think sort of replace her well replace the ones that are sort of aging out but she so she&#39;s in Strangers on a Train I think that&#39;s probably the biggest one she&#39;s in but she is plays a very odd character in Day of the Animals you ever seen that?</p>
<p>Bryan! (14:03.669)
Yeah.</p>
<p>Bryan! (14:07.742)
Look out.</p>
<p>Bryan! (14:25.08)
a million years ago.</p>
<p>Dave! (14:25.89)
Where Leslie Nielsen gives the weirdest fucking performance of his entire career. And that man has had a lot of weird roles. But this one is, if you ever want to watch like just a little bit airplane, like just slightly pre-airplane Leslie Nielsen, like sexually assault a woman, it is deeply upsetting. That movie is really, really weird.</p>
<p>Bryan! (14:32.908)
Yeah.</p>
<p>Bryan! (14:39.968)
Ha ha.</p>
<p>Bryan! (14:48.681)
Yeah, yeah, I&#39;ve seen those movies. I call them like killer animal movies. I&#39;ve seen them. Animal attack movies, killer animal attack. I&#39;ve seen them called natural horror movies. And I don&#39;t know how I feel about that. It&#39;s kind of like it&#39;s kind of like how people it&#39;s gasp. I suppose it&#39;s kind of like how people are kind of like doing what like what people used to do with like heavy metal where they would just sort of like attach like they create like new</p>
<p>Dave! (14:53.698)
The Animal Attack movies? I fucking love Animal Attack movies.</p>
<p>Dave! (15:02.775)
Okay, okay. All right, put it away!</p>
<p>Bryan! (15:16.264)
Like, what was it when the witch came out and everybody was talking about fucking folk horror, but all they could name was blood on Satan&#39;s claw and witch finder general and the wicker man. And they&#39;d make it sound like it was a big thing that existed before that. Yeah.</p>
<p>Dave! (15:23.394)
and Wicker Man.</p>
<p>Dave! (15:29.558)
And I mean, it is if you like fucking Russian, weird Russian indie movies from the 70s. And I don&#39;t, I don&#39;t like those.</p>
<p>Bryan! (15:34.696)
No, they&#39;re V.</p>
<p>Bryan! (15:40.356)
Hmm. No. Lastly, we got David Mooney, who plays Baby, and this was his... Oh, I&#39;m gonna say it did, because this was kind of like a breakout role for him, unfortunately, because prior to this, he had roles bearing names like photographer, barfly, and young man. And so at the end of the 70s, it appears that he bailed on acting and he became a high school teacher in Texas.</p>
<p>Dave! (15:43.766)
Don&#39;t expect much from him. This might have killed his career.</p>
<p>Dave! (16:07.65)
God bless you, baby.</p>
<p>Bryan! (16:08.672)
Yeah, see, I think he&#39;s great in this.</p>
<p>Dave! (16:12.822)
You also forgot Michael Pataki is in this as a fucking creep. Yes.</p>
<p>Bryan! (16:16.464)
Is he Dennis the Menace? Yes. Yeah. Like the thing is, is yeah, because there&#39;s a bunch of people in this also like Suzanne Zinor, who&#39;s like another like she was in a lot of TV stuff too, usually as a pretty blonde girl. She was on MASH. She was on</p>
<p>Dave! (16:21.038)
You might remember him from Halloween IV.</p>
<p>Dave! (16:35.487)
Which one is she?</p>
<p>Bryan! (16:37.269)
She&#39;s Alba.</p>
<p>Dave! (16:38.662)
Oh, she&#39;s the other sister. And she is basically here&#39;s what I imagined this time around when I saw her, I was like. I bet when Sherry Moon said, I don&#39;t know how to act, I&#39;m not an actor, Rob Zombie was like, here, here&#39;s a movie. Watch what she does and just do that in every other movie you&#39;re ever in. Now, I am not going to shit on Sherry Moon because I actually think that she is a decent actor and does not get enough credit. But I every frame this woman is in, I&#39;m like.</p>
<p>Bryan! (16:40.043)
Yes.</p>
<p>Bryan! (16:54.081)
Ha ha.</p>
<p>Bryan! (16:58.038)
Yeah!</p>
<p>Dave! (17:08.31)
Jesus Christ, it is like a one for one comparison.</p>
<p>Bryan! (17:11.784)
Yeah, yeah. Yeah. Also, like as we mentioned before, Mariana Hill, she plays the other sister, Germaine. I&#39;m trying to think if there&#39;s any</p>
<p>Dave! (17:20.91)
who was also at schizoid, which I watched recently. It was not good.</p>
<p>Bryan! (17:24.072)
That&#39;s the Klaus Kinski one. But that&#39;s the A side to a really great double feature though. Yep. So let&#39;s do some notes here. According to David Mooney, Abe Polsky took the seed from the story from some news item in the 60s about a family which did this very thing to a child to keep the welfare payments coming. Is this true? I doubt it.</p>
<p>Dave! (17:26.445)
Thanks for watching!</p>
<p>Dave! (17:51.242)
I mean, it&#39;s true in that way that like this movie is based on a true story. Like yeah, people do shit like this all the time. It&#39;s fucking horrifying. But I don&#39;t think it&#39;s quite like this.</p>
<p>Bryan! (18:01.204)
I got to wonder, like, I thought about this movie a lot in the last couple of days, just sort of in preparation of this, and like, I reached a sort of point where I was thinking, like, is it possible? Is it even remotely possible to keep a person in the state of arrested development like this into their 20s or 30s? And I don&#39;t, I mean, I&#39;m giving it way too much credit, but I really don&#39;t think in a real world context that you could do something like this. I think that you&#39;re just...</p>
<p>as you develop as a person, even if your caregiver is holding you back, you&#39;re going to eventually learn to talk, you&#39;re going to eventually start walking around on your own. But like, I think I&#39;m giving this way too much thought, but like I just don&#39;t</p>
<p>Dave! (18:40.773)
Yeah, I mean, I hate to break it to you. Abe Polsky, not a great screenwriter.</p>
<p>Bryan! (18:46.413)
He also wrote the gay deceivers.</p>
<p>Dave! (18:47.83)
He did, and I&#39;m guessing you&#39;ve never seen The Gay Deceivers. It is not a very good movie. It&#39;s pretty offensive. It&#39;s about two guys who, it&#39;s kind of like a Threes Company situation. It&#39;s two guys, yeah, it&#39;s two guys who pretend to be gay to get out of going into the military. It is notable only because I think it is Michael Greer&#39;s first movie, and again, you probably don&#39;t know who he is. He was a big theater guy.</p>
<p>Bryan! (18:50.756)
I have heard of it, I have not seen it.</p>
<p>Bryan! (18:59.752)
It&#39;s Threes company, right?</p>
<p>Bryan! (19:05.045)
Yeah.</p>
<p>Bryan! (19:13.913)
I know who Michael Greer is.</p>
<p>Dave! (19:15.306)
But yeah, he&#39;s he is great. It&#39;s the only reason to see the movie. Otherwise, it&#39;s pretty.</p>
<p>Bryan! (19:19.976)
Yeah. Also, this script had been kicking around Los Angeles for a couple of years before the movie was produced.</p>
<p>Dave! (19:25.654)
Yeah, because everybody read it and was like, uh, what? No.</p>
<p>Bryan! (19:28.492)
I, that&#39;s the thing is I, it is amazing that this movie exists at all because it is, if I read this and was like, you want me to be in this movie, this will be the end of my career, which it was not for basically everybody except for David Mooney. Um, but, uh, yeah.</p>
<p>Dave! (19:45.066)
And like, it doesn&#39;t make any fucking sense. It&#39;s bizarre. But I think what makes this movie so goddamn weird is that everybody plays this straight. There is no irony. This is not a tongue in cheek movie. They were all just like, we got a fucking job to do. Let&#39;s get in there and do it.</p>
<p>Bryan! (19:59.082)
Nope.</p>
<p>Bryan! (20:03.124)
Now you keep saying that this movie doesn&#39;t make any sense. I think it makes perfect sense.</p>
<p>Dave! (20:05.686)
Because it changes. It does not. It goes off the rails several times.</p>
<p>Bryan! (20:09.332)
Hahaha</p>
<p>Bryan! (20:15.065)
Oh yeah, once you hit that twist at the end, and then it twists again, I think it all falls, like all the pieces fall into place.</p>
<p>Dave! (20:22.654)
Also y&#39;all, if you do watch this, just know it is insanely ableist.</p>
<p>Bryan! (20:27.968)
Yeah, yeah. So, yeah, so what is it? Post had to basically be badgered into making the movie, which is understandable. Like we said, it is a very alienating affair, which is probably why I like it as much as I do, because it is there&#39;s not another movie like this. It is as unique as they come.</p>
<p>Dave! (20:47.694)
I wonder who, when they decided to make this, when this got greenlit by, I would love to know who, who is the intended audience for this? I mean, it&#39;s obviously, this is a drive-in picture, clearly, and it&#39;s a B picture at best.</p>
<p>Bryan! (20:57.644)
That&#39;s...</p>
<p>Bryan! (21:01.88)
Yeah, yeah. That&#39;s where it made its money. Like it definitely like in those shitty little theaters and cities, like, you know, that they call grind houses. It was that kind of an environment where the movie really kind of took off because it&#39;s just that kind of audience. It&#39;s fucking weird. And to sell it like the like all the poster is really the only kind of.</p>
<p>marketing that they did. And it&#39;s just a singular point of sale marketing poster that&#39;s a crib with like a you can&#39;t see the person, but there&#39;s obviously like an adult in it and like in one hand, he&#39;s got a hatchet. Yeah, yeah. And then standing off to the side is like a woman with a with a fucking teddy bear and a negligee like looking all sexy and shit. So like to try and like say like, oh, there&#39;s some sexy shit in this movie.</p>
<p>Dave! (21:37.384)
It is a very provocative, provocative poster.</p>
<p>Dave! (21:54.474)
Well, there is some sexy shit in this movie, and it is deeply uncomfortable.</p>
<p>Bryan! (21:58.661)
is so unpleasant. Yeah, it is. So the original</p>
<p>Dave! (22:02.862)
There&#39;s also an air of menace to this entire movie.</p>
<p>Bryan! (22:06.1)
The whole thing I saw Ruth Roman in one review referred to as casually sinister, which is such a great.</p>
<p>Dave! (22:13.61)
Yeah, the fuck, the two sisters look like straight up Manson girls.</p>
<p>Bryan! (22:17.948)
Oh, yeah, yeah. And I&#39;m sure that was deliberate. So the original audio mix of the movie included David Mooney&#39;s voice doing all the baby sounds. But subsequent releases, there was probably a VHS release of this. And if you can find it, that&#39;s probably got the original audio track on this. But when I remastered and brought to DVD, probably with I think Severn was the first company to really release it. They could not find the original audio materials, so they had to use like canned baby sounds for all of the</p>
<p>Dave! (22:46.036)
And that makes it even weirder.</p>
<p>Bryan! (22:48.452)
Way worse. That baby whale is so awful. It really, really strikes directly at the heart. Also, I believe this is the first movie that we&#39;ve done that&#39;s rated PG. And I don&#39;t know how the fuck this movie is rated PG. Like, oh, you&#39;re right. You&#39;re right. Yeah.</p>
<p>Dave! (23:02.603)
Joss is PG.</p>
<p>I mean, it&#39;s PG because it&#39;s 1973. So there&#39;s it&#39;s that or R and there&#39;s nothing explicit. I mean, they swear a couple of times. Everything else is kind of just implied. Now there&#39;s weird violence too, I guess, but.</p>
<p>Bryan! (23:14.876)
I suppose that is that it really is it really is like a buy the book like the MPAA wasn&#39;t like being guided by like opinion so much as they just had a checklist and we&#39;re like okay well yeah.</p>
<p>Dave! (23:27.53)
Yeah, no, they got halfway through this movie and we&#39;re like, I don&#39;t want to watch the rest. I&#39;m sure it&#39;s fine. And you know what? It&#39;s all in the back half.</p>
<p>Bryan! (23:36.112)
Yep. Yeah. So let&#39;s get into it. The movie opens with a title sequence on a dark study as our protagonist Anne leaps through a file containing photos of a boy who appears to grow older the more photos she looks at, but all the while remains in diapers. As she reaches the last of the photos, we see a fully grown ass man in a crib wearing a diaper. And then she shares the photos with an older woman who we will later learn is her mother-in-law.</p>
<p>and they study them with grim expressions and then exchange a look of approval. And then as the titles conclude, Anne arrives at the home of the Wadsworths.</p>
<p>Dave! (24:13.915)
I mean, you&#39;re already uncomfortable. I mean, this is this is less than a minute in. And I was just like, I don&#39;t know if I want to do this again.</p>
<p>Bryan! (24:17.884)
Right. And the music, the music playing over the titles, I believe is that. Yeah, because you&#39;ve got that like swinging crazy hippie jazz. And then there&#39;s also that like dower cello shit that they keep doing. Yeah. And I believe it&#39;s the cello stuff that&#39;s playing as she&#39;s like looking at the pictures. And it&#39;s all like super underlit, like it&#39;s almost like she&#39;s looking at this by like fucking firelight or candlelight or something.</p>
<p>Dave! (24:22.678)
The music in this is fucking wild.</p>
<p>Dave! (24:28.579)
Yeah.</p>
<p>Dave! (24:48.409)
Yeah, oh, no, you expect a black glove killer in this movie. A hundred percent. You don&#39;t get one.</p>
<p>Bryan! (24:52.096)
Yeah, yeah, it does. No, but it looks like this is we&#39;re being set up. So yeah, as the titles conclude, Anne arrives at the home of the Wadsworths. And this is where we first meet Alba, who&#39;s one of the Wadsworth girls. And then she&#39;s kind of bubbly in this scene, which is not how the character plays because there&#39;s the</p>
<p>Dave! (25:11.958)
Yeah, she comes across like, wrote a pen mark 15 years later.</p>
<p>Bryan! (25:17.822)
Yeah, and then we meet Mrs. Wadsworth, who in</p>
<p>Dave! (25:20.63)
Ruth Roman, Ruth Roman&#39;s performance brought to you by Winston Cigarettes.</p>
<p>Bryan! (25:26.682)
She&#39;s got the voice for sure. Yeah.</p>
<p>Dave! (25:28.854)
Yeah, it&#39;s like it&#39;s like who&#39;s afraid of Virginia Woolf, but like the sequel. Like what happened 20 years later?</p>
<p>Bryan! (25:36.524)
Yeah. Yep. She enthusiastically greets Anne and Anne is from the Yup. Oh, yeah, the fashion in this movie high 70s. It&#39;s it&#39;s all like Earth tones and</p>
<p>Dave! (25:41.15)
in a righteous caftan.</p>
<p>Dave! (25:53.206)
Yeah, no, this is like when people are like, oh man, the 70s, they must&#39;ve been so cool and hip and swinging. No, they were fucking ugly, just like the 80s. Here&#39;s why.</p>
<p>Bryan! (26:02.504)
This was peak polyester season too. Yeah. Everything&#39;s brown. Everything&#39;s orange. Everything is avocado. Like it&#39;s it was it was though. It was this which was that the style at the time.</p>
<p>Dave! (26:15.309)
your belt.</p>
<p>Bryan! (26:17.313)
So yeah, so Anna&#39;s is from the welfare office and we learn that she specifically asked to have this case</p>
<p>Dave! (26:23.162)
She says, I made a special effort to get this case. Why?</p>
<p>Bryan! (26:27.72)
Because it&#39;s just so interesting, she says.</p>
<p>Dave! (26:31.59)
Listen, I will tell you, I used to be a social worker. This movie is way off the mark, tell you that. Also, you don&#39;t look through cases and be like, you know what, I want this one. This one sounds fun and sexy. Because if you do say that, you need to be fired. Actually, I worked with a girl who did say that and did that, and she was fired. But hey, I hope it worked out.</p>
<p>Bryan! (26:35.531)
Ha ha</p>
<p>Yeah.</p>
<p>Bryan! (26:45.109)
Yeah.</p>
<p>Bryan! (26:54.173)
Ah, that&#39;s terrible, man.</p>
<p>She probably ended up at another welfare office.</p>
<p>Dave! (27:01.398)
Yeah, as a fucking client. Ayo!</p>
<p>Bryan! (27:05.099)
Hey, so we also find out that as well as Alba, Mrs. Wadsworth has another daughter named Germaine.</p>
<p>Dave! (27:12.678)
And Jesus Christ, when she enters this frame...</p>
<p>Bryan! (27:16.804)
She is, yeah, so everybody in this family is really, really weird, but there is a wildness and like an animalism to the way that she presents herself.</p>
<p>Dave! (27:26.446)
Okay, she has got a hee haw wig on. She has got a dress she stole from the set of the last picture show. And those shoes look like she found them on the side of the fucking road. This outfit is insane. It&#39;s like John Waters directed the Texas Chainsaw Massacre. It is so crazy. I laughed out loud.</p>
<p>Bryan! (27:45.424)
Yeah, yeah. And like every time she&#39;s on screen, like it&#39;s obvious it was very much a deliberate wardrobe decision because her both of the Yep. Yeah, she&#39;s supposed to be kind of naive and a little I don&#39;t know dim is kind of the impression that I get because</p>
<p>Dave! (27:54.146)
She&#39;s wearing a potato sack, basically.</p>
<p>Bryan! (28:09.352)
Both of the girls have very like distinct personalities where they&#39;re different, but they&#39;re both sort of arrested at a particular age. Jermaine&#39;s is like naive 13 year old, and she dresses in the way that she thinks like an adult woman does and she does her hair in the way that she thinks like an adult woman does, but she looks like a maniac.</p>
<p>Dave! (28:29.354)
Yeah, I mean, if you if I didn&#39;t know better and you were like, oh, directed by Tom Post, I would say somebody directed this movie.</p>
<p>Bryan! (28:36.845)
I was playing a great direction in this. So yeah, there&#39;s also a third child simply named baby. And</p>
<p>Dave! (28:47.33)
there is a great moment where she says she&#39;s talking to Ruth Rowan and she says, and your daughters, are they employed? And I thought, bitch, did they look employable to you?</p>
<p>Bryan! (28:56.248)
Yeah, but I mean, we get the answer to that where what is it? Alba teaches tennis and yeah, and Germaine does the occasional TV commercial.</p>
<p>Dave! (29:01.419)
Sometimes.</p>
<p>Dave! (29:05.43)
Which is wild.</p>
<p>Bryan! (29:07.308)
you know, for what do you imagine like toothpaste? Yeah. So, uh next, we go to Yeah. I mean, not colorful enough but sure. I can see it. So, Nick, okay. Yeah. Oh, yeah. Yeah. When they called him transvestites.</p>
<p>Dave! (29:11.968)
Not shampoo, I can tell you that much.</p>
<p>Dave! (29:17.398)
This is like, this movie&#39;s a drag queen&#39;s dream.</p>
<p>Dave! (29:28.298)
I mean like old school drag queen, like Ruth Roman alone.</p>
<p>Dave! (29:35.531)
Yes.</p>
<p>Bryan! (29:36.62)
Now, so now we go to meet baby, who is a grown ass man in his 20s, maybe his 30s. It&#39;s kind of hard to tell the 70s is a little murky when it comes to age. And he&#39;s</p>
<p>Dave! (29:48.446)
Look, I got to wonder, has this guy ever even seen a baby before? Because he is he is playing this like fucking Helen Keller in The Miracle Worker. Like he is writhing around, is wobbling his head back. Like, look, babies are curious. They&#39;re not like wounded animals.</p>
<p>Bryan! (29:53.804)
I think that the-</p>
<p>Bryan! (30:00.017)
Listen, I&#39;ve done-</p>
<p>Bryan! (30:08.256)
I have done the baby thing twice and it is you know if you&#39;re trying to you know communicate infant to me, he&#39;s doing a pretty good job. Like he doesn&#39;t have the focus. Yeah. Yeah, but I mean also he might have a head injury like they don&#39;t treat him very well. No, so it is feeding time now and</p>
<p>Dave! (30:20.986)
He&#39;s like infant with a head injury.</p>
<p>Okay.</p>
<p>Dave! (30:29.39)
It&#39;s true.</p>
<p>Dave! (30:34.562)
And this is when I thought, did everybody in this movie feel really stupid pretending this man is a baby? They must have. I mean, I get like that&#39;s what makes you an actor and me an idiot on a podcast, but still I&#39;d have a really hard time doing that.</p>
<p>Bryan! (30:42.089)
It must have been, it&#39;s.</p>
<p>Bryan! (30:50.732)
It had to have been they probably had to like reconcile some shit within because it&#39;s got to be really weird to be in the presence even when your expectation as a person is going to be acting when a person is acting like that though, like it&#39;s weird man. And there&#39;s like a whole there&#39;s like a whole sub fetish scene of people who do this. Maybe that I hope they&#39;re taking notes.</p>
<p>Dave! (31:19.847)
I had a long conversation about that last night, and I just kept saying, I don&#39;t know. I don&#39;t know how it works, and I don&#39;t want to know.</p>
<p>Bryan! (31:24.884)
Ha ha!</p>
<p>don&#39;t. That&#39;s that&#39;s you know what I hope everybody&#39;s enjoying themselves. I&#39;m fine you know living in the dark. So it&#39;s feeding time. Jermaine&#39;s got crazy eyes. And she was an asks to feed baby who again is a grown ass man and oversized high chair being spoon fed like an actual baby. This</p>
<p>His behavior is every bit an infant. And he coos and he squeals, and he has the same lack of focus that a baby has. And then next we cut to a scene where Mrs. Wadsworth is massaging baby&#39;s legs with... No, no, when they do this stuff is when I&#39;m like, ah, come on. Because the movie&#39;s fucked up enough without the weird sexual angle.</p>
<p>Dave! (32:03.421)
Oh, I didn&#39;t like this at all.</p>
<p>Dave! (32:16.426)
Yeah, no, I&#39;ll tell you right now, there is more than one in my notes that just says, holy fucking shit.</p>
<p>Bryan! (32:21.884)
Yeah. Because she&#39;s like massaging him with copious amounts of some kind of cream. And it&#39;s completely unnecessary because as well, it&#39;s ooh, ooh. And it&#39;s probably really hot on the set with lots of lights. It&#39;s also California. And so it&#39;s just. I could feel it. I could feel it when she&#39;s doing it. And it&#39;s like, I don&#39;t want to feel it. But.</p>
<p>Dave! (32:30.774)
Yeah, it&#39;s greasy. The whole thing&#39;s greasy. I don&#39;t like any of it.</p>
<p>Dave! (32:39.478)
Like my note actually says this makes me uncomfortable.</p>
<p>Bryan! (32:49.444)
The thing is, is he moves around like we&#39;ll see him like crawling shit. So it&#39;s not like his, it&#39;s not like his, you know, muscles are a threat of atrophy or something like that. He just doesn&#39;t walk. It&#39;s</p>
<p>Dave! (33:01.494)
And then Anne kisses him on the forehead, you know, like a regular social worker would do.</p>
<p>Bryan! (33:05.604)
Yeah, yeah. But this is when we also learn that Ann is hanging around the Wadsworth house like a lot. We find out that</p>
<p>Dave! (33:18.602)
Well, this is when they&#39;re walking to the car, right? And she&#39;s your main.</p>
<p>Bryan! (33:21.128)
Yes. So no, right. It&#39;s it&#39;s right before that. So so this is where we find out that she&#39;s been to the Wadsworth&#39;s house like three times in a week when she&#39;s really only supposed to be checking on them like twice a year. And then</p>
<p>Dave! (33:35.95)
I&#39;d say whoever was checking on them twice a year was a little bit remiss in their duties.</p>
<p>Bryan! (33:41.608)
Uh yeah. Yeah. But back at the office, Anne&#39;s boss is giving her understandably a hard time for spending so much time with the Wadsworths because she has other cases. Um and he insists that uh or rather she insists that something wrong is happening in the house and she&#39;s not wrong but it&#39;s a perfectly reasonable assumption to make.</p>
<p>We learn something strange here also that the last caseworker for the Wadsworths mysteriously vanished.</p>
<p>Dave! (34:16.76)
You&#39;re never going to get answers to any of these questions, so don&#39;t worry about it.</p>
<p>Bryan! (34:18.736)
Yes, I well, I got a little I got a little speculation in here a little later on. But her boss is like, hey, people disappear all the time. It&#39;s like, that&#39;s kind of a fuck. Yeah, usually they quit.</p>
<p>Dave! (34:27.225)
Yeah. Hey buddy, no they don&#39;t.</p>
<p>Dave! (34:32.494)
Also, you should be concerned if you have an employee that just doesn&#39;t show up ever again. At work or anywhere else.</p>
<p>Bryan! (34:37.556)
Yeah. Well, also, this was the time of like, this was the 70s where people would fucking become a victim to a serial killer and the cops would be like, hey, what do you want us to do? Kids run away all the time. Yeah. Like this was this was prime Dean Coral era. Yeah. So the next day, Anne&#39;s back at it playing with baby. Well, Jermaine looks on troubled, insisting that bait.</p>
<p>Dave! (34:49.467)
What are we supposed to do, find them?</p>
<p>Dave! (34:55.595)
Ugh.</p>
<p>Dave! (35:05.686)
Listen, Jermaine has got some wild lesbian energy.</p>
<p>Bryan! (35:08.924)
Well, yeah, and it comes out here. She insists that baby isn&#39;t as smart or capable as he seems. And then in walks Alba, who we learn is the basically the polar opposite of Jermaine, where Jermaine&#39;s behavior is like a naive teenage girl in an adult&#39;s body. Alba is like a bitchy teenager. And she&#39;s really she&#39;s fucking terrifying.</p>
<p>Dave! (35:10.751)
Yeah.</p>
<p>Dave! (35:27.423)
and kind of a monster.</p>
<p>I mean, she is Sherry Moon&#39;s character from what&#39;s that second one? Devil&#39;s rejects. She&#39;s that.</p>
<p>Bryan! (35:36.608)
Oh, yeah, you know the one. Devil&#39;s rejects. Yeah. Her face also is in a permanent frown throughout this entire movie. She&#39;s really rough with baby and seems to have no compassion for him. And so they kind of leave the scene. And then Jermaine and Anne talk a bit as they walk back to her car and the whole time. Anne has been introducing herself as</p>
<p>But now they talk about her husband and she refers to him in the past tense.</p>
<p>Dave! (36:10.494)
And this is the greatest soap opera moment in this film because she says, Roger was an architect and then she stops and freezes and they focus and the only thing that&#39;s missing is that great musical sting.</p>
<p>Bryan! (36:20.undefined)
Yeah.</p>
<p>Bryan! (36:23.912)
Yeah. So, so it&#39;s almost as though he&#39;s dead. It&#39;s like he&#39;s like a troubled book comes over her face. And meanwhile, Jermaine seems into Anne like into her. She&#39;s like, tell me, do you like physical exercise?</p>
<p>Dave! (36:36.331)
Yeah, she is.</p>
<p>Dave! (36:41.982)
Yeah, do you ever want to go hiking and make out with a lady? I mean she doesn&#39;t say it, but she says it, you know?</p>
<p>Bryan! (36:45.952)
No, no, but what she what she does say is so yeah, she&#39;s basically says you want to go hiking with me and says I usually spend Saturdays with Roger who is this guy fucking dead or is he not but Jermaine replies you never know what you&#39;re missing until you try it. Indeed. Indeed Jermaine.</p>
<p>Dave! (37:05.994)
Eh... Ho-holy fucking shit.</p>
<p>Bryan! (37:09.808)
Yep. But Ann is.</p>
<p>Dave! (37:11.006)
Also, like that would be like the least inappropriate thing they do in this movie.</p>
<p>Bryan! (37:15.885)
Yeah, yeah. But Anne is a Scorpio, she tells her, and they&#39;re cautious. But just before she arrives away, Germaine informs her, Scorpios are not cautious. They&#39;re dangerous.</p>
<p>Yeah, take that just keep it a little note there. And so that night, Anne looks at a series of slides of her and her husband.</p>
<p>Dave! (37:38.622)
and just sits up drinking watching a slideshow of her maybe dead husband.</p>
<p>Bryan! (37:45.605)
maybe alive, we don&#39;t know. Uh whatever it was that happened hasn&#39;t said it yet. It&#39;s not</p>
<p>Dave! (37:51.439)
This is when I was like, oh, baby reminds her of her dead husband. And then I thought, oh, I don&#39;t like where this is going. And guess what, y&#39;all? That&#39;s where it&#39;s going.</p>
<p>Bryan! (37:56.124)
Yep. Yeah. So in her grief, her mother-in-law comforts her and I gotta tell you their relationship is so fucking strange.</p>
<p>Dave! (38:10.998)
Because this is a character that they were like, should we give her more lines or something? And then they were like, you know what? It&#39;s fine, don&#39;t worry about it. And then you get to the end and you&#39;re like, wait, she&#39;s crazy?</p>
<p>Bryan! (38:16.161)
No, no, let her just.</p>
<p>Bryan! (38:22.552)
Yeah, I love it. I fucking love it because she just looms and like she occasionally she just like emerges from the shadows.</p>
<p>Dave! (38:31.934)
And she is very severe. Yeah, she&#39;s got a real Max Shrek energy. She just kind of like shows up.</p>
<p>Bryan! (38:37.676)
Yeah. So, um, back at the Wadsworth&#39;s house, a babysitter watches over baby.</p>
<p>Dave! (38:42.319)
Adrian King is just babysitting one night.</p>
<p>Bryan! (38:44.972)
I thought the same thing too. I was like, oh my God, it&#39;s Adrienne King. It&#39;s not, but it&#39;s not, but it looks like her, like a lot like her.</p>
<p>Dave! (38:47.954)
It is not Adrian King.</p>
<p>Because this surely would have tanked Adrian King&#39;s career.</p>
<p>Bryan! (38:54.62)
Oh yeah, we wouldn&#39;t have gotten her on Friday the 13th for sure. But yeah, she&#39;s talking to her boyfriend or something and we can hear baby crying from upstairs.</p>
<p>Dave! (39:02.498)
The conversation is great though, because all you hear is say, now what kind of question is that? Of course I&#39;m wearing panties.</p>
<p>Bryan! (39:08.156)
Yeah, yeah, yeah. Just before she hangs up, she&#39;s like, fine, I&#39;ll try to come over later. And yeah, I&#39;ll leave him off.</p>
<p>Dave! (39:14.391)
God damn it.</p>
<p>Bryan! (39:15.484)
Yep. So we go up with the sitter and find baby in his room in need of a diaper change. Yeah. Nope. No, I don&#39;t. But there&#39;s also a very strange detail to the room. The walls are covered in really fucking disturbing art that looks so it looks like it might have been created by a child. But here&#39;s the thing. Baby doesn&#39;t have that like level of development. Like we watch him</p>
<p>Dave! (39:21.716)
Ugh, I didn&#39;t like this either.</p>
<p>Dave! (39:33.046)
I have a note about one of them later.</p>
<p>Dave! (39:43.99)
No, he can&#39;t even hold his head up without fucking waggling all over the place.</p>
<p>Bryan! (39:47.836)
Right, right, right. Like his like it requires a concerted effort and both hands to catch a very slowly rolling ball like there&#39;s no way this kid to the art in this room.</p>
<p>Dave! (39:57.318)
One of my favorite ones, it looks like it&#39;s straight out of the Babadook. It&#39;s like, I&#39;m assuming it&#39;s Baby holding the hand of what I&#39;m going to guess is supposed to be a sister, but it just looks like a giant hooker. And you&#39;re thinking, I think you mean Sex Worker. And I say, no, I mean a hooker. Like, this is like Frank and Hooker.</p>
<p>Bryan! (40:01.586)
Ha, yeah!</p>
<p>Bryan! (40:09.075)
Yeah.</p>
<p>Hahaha!</p>
<p>Yeah, no, literally they have a hook hand.</p>
<p>Dave! (40:19.658)
Yeah, like it is monstrous. Slash hilarious.</p>
<p>Bryan! (40:22.224)
It&#39;s so fucking strange. But yeah. Yeah. But like so. So this just kind of sets the table for this horrifying scene where we she goes in and she&#39;s going to put another diaper on him and we cut to a close up of baby just enjoying the process of having his business cleaned up and this diaper changed. Um and then</p>
<p>Dave! (40:47.37)
And that&#39;s not all. That is not where the scene ends. It actually gets worse.</p>
<p>Bryan! (40:50.06)
like, no, it gets <em>**</em> a thousand times worse because like, he&#39;s, you know, now he wants to play and he wants to get out. So, she she plays with him a little bit and she throws his like teddy bear and he dives for it and he bonks his head and it and it erupts into this like these horrifying like ear piercing piercing whales and she goes to like comfort him and</p>
<p>Dave! (41:17.283)
You ever read The Grapes of Wrath?</p>
<p>Bryan! (41:19.634)
Ah, she</p>
<p>Dave! (41:21.898)
That. It&#39;s the end of the Grapes of Wrath, everybody.</p>
<p>Bryan! (41:25.4)
she he like latches on and is like trying to like nuzzle and like breastfeed and she&#39;s like no baby no and then eventually it&#39;s like oh maybe baby like oh okay and then she like opens her shirt and just hands it over to him but now we cut to uh the Wadsworth ladies returning from wherever they were. I they did. They did</p>
<p>Dave! (41:36.178)
Yeah, SHE can send way too easily.</p>
<p>Dave! (41:48.522)
They look like they had a great time wherever they were.</p>
<p>Dave! (41:54.894)
I am.</p>
<p>Bryan! (41:55.016)
So they were out for Chinese food and they walk in on this horrifying scene just as the sitter seems to be like really enjoying herself and understandably horrified. They begin to beat the fucking shit out of this girl.</p>
<p>Dave! (42:07.69)
Yeah, the listener says nothing happened, the Ruth Roman says nothing happened with your damn tit in his mouth and you call that nothing? Look, it&#39;s not a great Ruth Roman, but I quit smoking, so... I do what I can, okay?</p>
<p>Bryan! (42:15.276)
that she whips her with a, it&#39;s, it&#39;s pretty good. She whips her with a belt. And then</p>
<p>Dave! (42:22.838)
Well, my question was, why does Baby have an adult woman&#39;s belt in his bedroom? I guess for whipping.</p>
<p>Bryan! (42:29.748)
for whipping. Yeah. Cuz we&#39;re gonna find out they&#39;ve got like implements of torture. So, yeah. But uh yeah, what is it? Uh yeah and if you what Alba says like if you tell anybody, we&#39;ll call the police and tell them you were molested in a mental case. So, yeah. So, uh yeah and so now, it&#39;s the next day or you know, it&#39;s just some other day and Ann returns again, finds Alba with baby and Mrs. Wadsworth.</p>
<p>Dave! (42:45.398)
Yep.</p>
<p>Dave! (42:56.605)
They&#39;re outside, he&#39;s in a giant wooden playpen wearing what I think is an Angora sweater in the summertime?</p>
<p>Bryan! (43:02.852)
Oh, it must have been so hot. It looks so uncomfortable. Yeah.</p>
<p>Dave! (43:05.334)
Yeah, everything about this movie is uncomfortable. The clothing, the furniture, the toys, I&#39;m just like, God, it all looks just like abrasive and terrible.</p>
<p>Bryan! (43:14.844)
Yeah. And uh Mrs. Wadsworth isn&#39;t around because she&#39;s off playing bridge every Tuesday and Thursday. And um Alba is particularly guarded with Anne and they spar a little bit and then</p>
<p>Dave! (43:27.898)
but in Alba&#39;s defense, Anne&#39;s kind of an asshole.</p>
<p>Bryan! (43:31.156)
And here&#39;s the thing, Anne is like if I were if like I the thing is the Wattsworths are objectively fucked up people like this is</p>
<p>Dave! (43:32.726)
Like, and mind your fucking business, all right?</p>
<p>Dave! (43:41.822)
Oh yeah, no, everything about their relationship with, I mean, I guess everybody is antagonistic. But in kind of a fun way.</p>
<p>Bryan! (43:47.008)
like, yeah, but I like, well, yeah, like, I like the people that hang out with but like at the same time, Anne is really inappropriate. Like, this is not what, this is, she&#39;s not doing what she&#39;s supposed to do. She&#39;s obsessed.</p>
<p>Dave! (44:02.986)
No, like out the gate, she is a lunatic.</p>
<p>Bryan! (44:06.524)
Yeah. So, so, phone rings and then um Alba goes to get it and so with some one on one time with baby and remarks, I bet you&#39;d like a playmate. So, write that down and then she helps baby to stand up just in time for Mrs. Wadsworth to see him and she is upset with this. Uh they talk a little bit and suggest that she sends baby to a school for developmentally disabled children.</p>
<p>Dave! (44:21.837)
Mm-hmm.</p>
<p>Bryan! (44:36.052)
Mrs. Wadsworth is resistant, but her protection of baby at this point actually seems kind of genuine. And the three women in the house go out of their way to like protect him. There&#39;s but and there seems to be some hidden motive that we&#39;re gonna get a little like we&#39;re gonna get a real good look at but like at the in the moment it appears like we got to keep this guy like this so we can keep the checks rolling in.</p>
<p>Dave! (44:58.699)
A spoiler, that hidden motive is misogynistic.</p>
<p>Bryan! (45:03.517)
Um so the differences between Mrs. Wadsworth and Anne is all like in Anne&#39;s optimism and so Mrs. Wadsworth asks for a sort of demonstration of baby&#39;s potential and so Anne plays fetch with baby who can catch the ball but he won&#39;t return it. Like as he&#39;s going to get it, he keeps like looking over his shoulder at his mother who&#39;s like giving him like the dagger eyes.</p>
<p>Dave! (45:28.174)
I mean, you know, this movie is a bizarre trip, but I gotta say Ruth Roman, she&#39;s really acting the shit out of this part.</p>
<p>Bryan! (45:37.448)
You gotta give it to her. She&#39;s so good, man.</p>
<p>Dave! (45:39.35)
Like, I think she&#39;s great in this.</p>
<p>Bryan! (45:41.552)
She&#39;s definitely like one of my favorite characters. And it&#39;s something like something just about her presence. There&#39;s.</p>
<p>Dave! (45:46.498)
I feel like she kind of had like this, she had one, she&#39;s a bit of a one trick pony. Like, I feel like this is how she&#39;s in, in everything I&#39;ve ever seen her in. This is basically the character she plays. It&#39;s that real, yeah, it&#39;s that real kind of like jaded, I&#39;ve seen it all, you know, sure, you&#39;ll take me out, but you&#39;re really gonna end up with her type of lady.</p>
<p>Bryan! (45:56.201)
Tough chick.</p>
<p>Bryan! (46:06.3)
Yeah. For sure. So, Mrs. Wadsworth informs her that even a dog can do that.</p>
<p>Dave! (46:08.334)
It&#39;s a real Stockard Channing.</p>
<p>Dave! (46:18.97)
You call that progress? Even a dog can do a trick like that. God bless you, Ruth Roman.</p>
<p>Bryan! (46:22.668)
Yeah. So once and is gone, however, she&#39;s very disappointed in baby who speaks for the first time. He just says he just says mama all the while a dour cello piece plays. Now we cut to Alba punishing baby with a fucking cattle prod.</p>
<p>Dave! (46:45.654)
This is where it starts to feel like a Herschel Gordon Lewis movie.</p>
<p>Bryan! (46:50.688)
So she&#39;s like shocking him and then we cut to like, yeah, and she&#39;s like, we cut to the, we cut to the like closeups of her, like, like shocking him with like this horrible sneer. She&#39;s like, baby doesn&#39;t talk. It&#39;s in the trailer. And then, and then Jermaine comes up and she&#39;s like, stop, you&#39;re going to kill him. And she&#39;s like, he&#39;s got to learn. This is how we do it. And then Mrs. Wadsworth comes in and takes the caliprod away, shocks Alba with it. And then she extended, then right before the scene kind of cuts over, she goes,</p>
<p>Dave! (46:51.894)
but without the humor.</p>
<p>Dave! (47:15.446)
Yeah. And I thought, well, wait a minute. You don&#39;t want to cattle prod him, but you&#39;re going to lock him in the closet? All right. I don&#39;t have children. I don&#39;t know. Maybe that&#39;s just what you do.</p>
<p>Bryan! (47:20.34)
Get him in the closet.</p>
<p>Bryan! (47:29.224)
Hmm. Yeah.</p>
<p>Bryan! (47:35.48)
Nah, nah. So that night, Germain sneaks into his room in this in the I know.</p>
<p>Dave! (47:43.045)
I... uh...</p>
<p>This feels like an afterthought, and that is an afterthought that should have been left out.</p>
<p>Bryan! (47:49.652)
That&#39;s this. Okay. So this whole fucking angle could have been omitted entirely because without it, the movie remains the same. It&#39;s just as upsetting. Yeah. She takes off. She comes into this like slinky little Magdalene Jay and he takes it off and steps into his crib and it cuts there but like the implication is so dire and gross like we just didn&#39;t need to do this, you know.</p>
<p>Dave! (48:01.014)
Yeah, she&#39;s gonna have sex with him, everybody. She&#39;s gonna have sex with him. That is what is implied.</p>
<p>Dave! (48:18.062)
I don&#39;t like it. I don&#39;t like that.</p>
<p>Bryan! (48:18.908)
I don&#39;t like it. So, the next, again, like we cut to, I keep saying the next day, but it&#39;s really kind of unclear. It doesn&#39;t, the flow of time in this doesn&#39;t really matter, but yeah. And goes to the special kids school to meet with the director and that&#39;s what it says on the side. Yeah. And we see, we see kids at play in the scene that, okay, so I kept seeing that people were like, this is exploitative and this</p>
<p>Dave! (48:27.062)
It could be four days, it could be eight years, who knows?</p>
<p>Dave! (48:32.95)
The School for Exceptional Children.</p>
<p>Bryan! (48:48.836)
I don&#39;t, I didn&#39;t really see it that way. Like this is just, it&#39;s, it&#39;s the, the environment, the, well, here&#39;s the thing, the environment.</p>
<p>Dave! (48:50.654)
It&#39;s. It&#39;s exploit. It&#39;s exploitative in that way that the Sentinel is where it&#39;s like you&#39;re trotting out these children to be in this movie. These kids are probably just students at the school that don&#39;t want to be in your movie. It&#39;s that kind of thing where like you&#39;re putting them on display in a weird way. It could have been a lot worse.</p>
<p>Bryan! (49:06.025)
Yeah.</p>
<p>Bryan! (49:12.052)
you know, it may have been it may have been it may I suppose that the intention the intention at the time was shocking because this whole movie is like shocking, right? But the thing is the way that this school is portrayed is like a warm and caring environment where it&#39;s just children playing and you see them like helping one another and there&#39;s like people who are like there to see to their best interests. Like it&#39;s not like</p>
<p>Dave! (49:40.716)
Yeah, I mean, I think the issue is that they shouldn&#39;t really be in the movie at all. Like, that&#39;s that it&#39;s like the Sentinel. It&#39;s like, you don&#39;t need this.</p>
<p>Bryan! (49:44.38)
I guess. I guess. That&#39;s okay. That is the thing. Like, like just like I said in the last scene, you could have done this just going to going to the school and talking to the director without the whole scenes with the children because the children all appear quite sweet but like you know, it&#39;s yeah like this is a this is a far cry from like the Willowbrook State School. You know, like this is not it&#39;s not the way that&#39;s portrayed is not the way that I think it</p>
<p>Dave! (50:00.066)
Yeah, they&#39;re just children in a school. They don&#39;t need to be there.</p>
<p>Dave! (50:13.166)
But when people say shit like that, I&#39;m always like, what fucking movie are you watching? Of course it&#39;s exploitation. Have you seen the rest of this movie?</p>
<p>Bryan! (50:19.788)
Yeah, yeah. The expectations are a little, are a little... Yeah.</p>
<p>Dave! (50:22.13)
It&#39;s 1973. This is pristine compared to a lot of the shit that was being cranked out.</p>
<p>Bryan! (50:28.128)
Yeah. So, um and is now talking to the director of the school and she um she&#39;s talking about baby. Yeah, the offices in all of this are like pure bureaucratic nightmare but yeah, she she&#39;s talking to the director about baby in a kind of metaphorical manner but hold on to this thought. She asks him, can you imagine being buried alive?</p>
<p>Dave! (50:36.371)
in the saddest office I&#39;ve ever seen.</p>
<p>Bryan! (50:56.956)
And what she&#39;s talking about is baby being stuck in his place like emotionally. But hold on to that.</p>
<p>Dave! (50:57.113)
Oh, oh.</p>
<p>Dave! (51:03.062)
Yeah, but you know, if you wanted to turn the tables, say.</p>
<p>Bryan! (51:06.664)
Yeah. So, um, she&#39;s again, she&#39;s talking to him. She&#39;s telling him that she thinks that he&#39;s stuck in this state because of negative reinforcement and she&#39;s right. The Wadsworths are also supposed to meet her at the school, but they&#39;d never show up. And when Ann calls them, Jermaine answers and tells her to. Yeah. Oh, God. Yes, because Jermaine says, call your boss who she does, who then removes her from the case and holy shit.</p>
<p>Dave! (51:24.622)
This is when Anne loses her goddamn mind.</p>
<p>Bryan! (51:36.14)
this like the her the entire he fucking did. Yeah, so from there and drives over to the Wadsworth&#39;s house to confront Mrs. Wadsworth on the porch and they argue. And then Mrs. Wadsworth says, you want them for yourself, which would seem. Yes, it would seem like a pretty reasonable thing to assume, given how invested Anna&#39;s and baby&#39;s welfare. But that&#39;s also ridiculous, isn&#39;t it? I mean, I mean.</p>
<p>Dave! (51:36.258)
Hey, that boss made the right choice.</p>
<p>Dave! (51:53.89)
To which I said, what?</p>
<p>Dave! (52:03.995)
Why would Anne want a developmentally disabled adult? Oh no.</p>
<p>Bryan! (52:07.392)
Uh huh. Why? Why would she? So back at home, we find out that Anne is having a swimming pool installed in her backyard. And we also learn that Mrs. Wadsworth called and asked that Anne call her back, which she does. But something peculiar happens here. Her mother-in-law picks up one of the other phones in the house and listens in. So to younger listeners, that was a thing that used to be able to do.</p>
<p>Somebody would make a phone call, you&#39;d run over to one of the other phones, pick it up.</p>
<p>Dave! (52:36.334)
I thought you were gonna say something about the swimming pool and I was like, no, I think kids understand what swimming pools are. You were talking about the rotary phone.</p>
<p>Bryan! (52:40.244)
No, no, the phone, the telephone thing. Yeah, the way that you could like pick up another phone and just listen to listen to somebody else&#39;s call. Yeah. So she goes over and like</p>
<p>Dave! (52:45.27)
The extension, if you will.</p>
<p>Dave! (52:49.73)
But then I thought, well, why is she just listening to the call? She&#39;s not gonna say anything. Just fucking tell her what she said when you&#39;re done. You&#39;ll have to be all weird and cryptic about it. But you know what? You&#39;re already weird and cryptic, so I guess lean in.</p>
<p>Bryan! (52:58.984)
Yeah, but you know what else you get? She gets to they get to frame her against a backdrop of that, like that weird like knife collection that includes what I might. Yeah. Oh, God. With with a split screen. Yeah. And so she&#39;s there with like all these knives behind her. And one of them, what you might call Chekhov&#39;s meat ax. There&#39;s also a machete among other knives. So. Yeah.</p>
<p>Dave! (53:07.69)
Yeah, you know who should have made this movie? Brian De Palma. I think it would have been really great. Yeah.</p>
<p>Dave! (53:25.398)
thing you use in the kitchen at home. A machete.</p>
<p>Bryan! (53:28.948)
and the gigantic meat axe that looks like it takes two hands to wield. So Mrs. Wadsworth apologizes for her behavior earlier that day, tells Anne that they intend to cooperate, but they also invite her to Baby&#39;s birthday party where she could see where she could see Baby in a loving environment surrounded by friends and family. The yeah, the party is an absolute freak show.</p>
<p>Dave! (53:45.679)
Oh man.</p>
<p>Dave! (53:51.778)
Rounded by a bunch of weird hippies.</p>
<p>Dave! (53:56.982)
What the fuck is this party? Who are these people? Where did they come from? And why don&#39;t they think this is weird?</p>
<p>Bryan! (53:59.132)
It&#39;s all like they&#39;re all the local, like, you know, like hop heads and dopers and you know, whatever, whatever else.</p>
<p>Dave! (54:07.606)
They really are. And I just thought like, why when these when this shit gets portrayed in movies, because this is a very it&#39;s like, it feels like it&#39;s pulled out of like an episode of drag death.</p>
<p>Bryan! (54:18.12)
I was gonna say, everybody at this party is everybody that they ever arrested on Dragnet.</p>
<p>Dave! (54:23.158)
Yeah, why does it always feel like when they portray movies like drug parties in movies in the 60s, it just feels like a fucking absolute drug fueled nightmare.</p>
<p>Bryan! (54:32.924)
It&#39;s yeah, it&#39;s like a orgy of madness. Like just.</p>
<p>Dave! (54:35.402)
But in like a real low rent house, it&#39;s like maybe someone was killed there not too long ago.</p>
<p>Bryan! (54:40.924)
Yeah, perhaps in the basement.</p>
<p>Dave! (54:44.726)
And then they all start dancing and it&#39;s just like, they all look like a bunch of white people in an episode of Scooby-Doo. It&#39;s a real swingin&#39; time, but nobody really knows how to dance, they just sort of shake their arms around a little bit.</p>
<p>Bryan! (54:50.62)
Yeah, yes. Yeah, it&#39;s that.</p>
<p>Bryan! (54:57.128)
It&#39;s that it&#39;s also it&#39;s that canned like library rock music that they use when like they just don&#39;t want to hire a band to like write a song or whatever. And it all sounds the same. It&#39;s like in every beach party movie in every jallow. It&#39;s it&#39;s the same like just session musicians just jamming. And then it ends up in.</p>
<p>Dave! (55:19.106)
But I&#39;ll tell you what&#39;s different. Germaine really spruces up that wig this time around.</p>
<p>Bryan! (55:22.82)
My God, dude. So yeah. So now her hair is all like frizzed up and teased out.</p>
<p>Dave! (55:28.394)
Now, if you have never seen the movie, it is freezed up to begin with. It is the ratted, fucking madded madness wig. I have never seen anything like it, and now she has done more to it.</p>
<p>Bryan! (55:40.764)
Yeah, and whenever and also a thing that I noticed in this scene. Animals have taken have taken up occupation of this of this wig. Also her makeup in this scene is also particularly strange and also they light her. They light her from the bottom so.</p>
<p>Dave! (55:42.442)
The only way to get rid of this wig at the end is to burn it.</p>
<p>Dave! (55:57.502)
It&#39;s like something from Night of the Demons.</p>
<p>which everyone always looks great when you light them from the bottom. Never take a selfie of yourself with your camera sorta pointed down. It&#39;s like that, but if you&#39;re wearing a real disgusting ratty wig.</p>
<p>Bryan! (56:14.722)
So, yeah. So now, Anne is now hit on by Dennis, who is a self-described skin freak while he smokes a joint.</p>
<p>Dave! (56:21.334)
Skin you got beautiful skin. Hey, I&#39;m just a skin freak Jesus Dennis He&#39;s like 45 years old</p>
<p>Bryan! (56:25.256)
Yeah. Are you are you a dermatologist? Yeah, yeah, I mean, because that&#39;s the thing is he&#39;s described as I know, but like, this is definitely there&#39;s an age difference between him and Alba because he&#39;s one of her what germane says he&#39;s one of Alba&#39;s many bows.</p>
<p>Dave! (56:32.918)
Which I mean no shade, cause you know.</p>
<p>Dave! (56:45.75)
The Halberst got real bad taste, I think. I&#39;m judging based on this one guy, but something tells me.</p>
<p>Bryan! (56:48.35)
Alba&#39;s...</p>
<p>I&#39;m not sure. Alba&#39;s got a thing. And we&#39;re going to we&#39;re going to get a little glimpse into that into that world in a moment. But there&#39;s yeah, there&#39;s something going on there. So,</p>
<p>Dave! (57:04.11)
I mean, and it&#39;s not just Dennis. I feel like every man at this party is at least 20 to 25 years older than every other woman. Except for Roman, who comes breezing into the scene in her sparkliest, shiniest caftan.</p>
<p>Bryan! (57:13.256)
Yeah. Yep.</p>
<p>Bryan! (57:33.278)
the</p>
<p>Dave! (57:42.19)
I mean, at this point, I was just kind of like, is Anne supposed to be the protagonist of this movie? She is thoroughly unlikable. Like, who am I rooting for? Ruth Roman.</p>
<p>Bryan! (57:48.788)
that&#39;s the thing. So, the very first time that I watched this, the very well, here&#39;s the thing. This is kind of the point where that switch turns because when I first watched, yeah, when I first watched this, I was like, okay, Ann&#39;s weird but these people are weirder. So, like, yeah, she&#39;s the good guy but then this scene happens and also like the stuff right before it were like,</p>
<p>Dave! (58:00.342)
Yeah, this is where it becomes a different movie.</p>
<p>Bryan! (58:17.412)
she kind of melts down about like losing control of this of this family. So all of a sudden like a switch turns and it&#39;s like, okay, maybe the Wadsworths aren&#39;t as bad. Maybe she&#39;s worse. We don&#39;t really know yet but like the there&#39;s they&#39;re tightening the screws a little bit and also in this scene, Mrs. Wadsworth talks to baby and about baby like with everybody around him in this way that&#39;s</p>
<p>Dave! (58:45.741)
Oh, and baby is dressed like Uncle Sam. Just for the party. You know what? If anyone&#39;s listening to this conversation, they would think, what the fuck are they talking about? But no, this is exactly how this movie unfolds.</p>
<p>Bryan! (58:47.688)
Yep.</p>
<p>Bryan! (58:56.66)
Yeah. So, yeah, the point I was trying to get to is Mrs. Wadsworth&#39;s affection for him does seem genuinely motherly and real. Everything surrounding that is fucked up and bad and wrong, but like there&#39;s that little kernel at the center of it that in this moment as Anne is starting to kind of come apart.</p>
<p>we I think this is the part where this the movie is really kind of good at sort of ratcheting up the tension and manipulating the viewer because all of a sudden I&#39;m like, maybe the Wattsworths aren&#39;t as bad like I mean presented with this pool of characters. I kind of got to switch my allegiances at this moment.</p>
<p>Dave! (59:39.877)
Oh no, I was kind of on mirth rum inside from the beginning. And not just because of the sparkly caftan, but that helped.</p>
<p>Bryan! (59:43.274)
Hahaha</p>
<p>Yeah. So, yeah. So, we also see that things worked out with the babysitter because she&#39;s at the party too.</p>
<p>Dave! (59:55.746)
Well, keep your mouth shut, you get invited to this swingin&#39; party.</p>
<p>Bryan! (59:59.144)
Yep. Yeah. Hey, no hard feelings, right? I mean, shit happens.</p>
<p>Dave! (01:00:02.538)
Yeah, sometimes you gotta get beat with a belt, but you get to go to a party later. Story of my life.</p>
<p>Bryan! (01:00:08.188)
Yeah. Oh, also, this is the part where uh what Mrs. Wadsworth uh offers Anna a drink and uh she&#39;s got these fucking great lines that I wrote down. She she tries to like give her like oh you know some liquor and she says, hey, this will help you get with get with it.</p>
<p>Dave! (01:00:26.15)
Oh yeah, no, this is real, like, uh, old lady tries to sound hip.</p>
<p>Bryan! (01:00:31.688)
Yeah, because she also says like, hey, like, why don&#39;t you, like, you know, put down your hair and hang loose.</p>
<p>Dave! (01:00:37.418)
Because she does. Ruth Roman takes to the dance floor and it is the greatest scene in the movie, everybody.</p>
<p>Bryan! (01:00:40.316)
Yep. She grabs, she grabs Elliot and says, Elliot, let&#39;s work out. And then they go dance.</p>
<p>Dave! (01:00:47.114)
And this is when I made the note about how everybody looks like they&#39;re dancing like an episode of Scooby-Doo.</p>
<p>Bryan! (01:00:51.676)
Yeah, I love I love groovy hippie slang like that shit is the best.</p>
<p>Dave! (01:00:58.878)
And it goes without saying, there are no black people at this party. They are all white. Everybody in this movie is white.</p>
<p>Bryan! (01:01:04.794)
Don&#39;t be ridiculous. So, Alba and Anne get into the most intense game of darts of all time.</p>
<p>Dave! (01:01:13.514)
Which seems like a real weird choice. Why darts? Do you think like whoever What&#39;s-Her-Face, Ange-Dick Comber was like, you know what I&#39;m really good at? Darts. Can we work that in? Probably.</p>
<p>Bryan! (01:01:16.083)
I want to take your side and be like, just be like...</p>
<p>Bryan! (01:01:22.676)
Well, it&#39;s also because she&#39;s like, I think it gives her a chance. This is a moment where she&#39;s like she&#39;s whipping them at the dartboard and hurt.</p>
<p>Dave! (01:01:29.33)
Oh, yeah, she is definitely selling. I&#39;m going crazy.</p>
<p>Bryan! (01:01:32.968)
Yeah, she&#39;s like she has all sorts of like weird like my grindset mindset mantras that she keeps throwing at Alba like losing it. Like get the fuck what the fuck like loosen up a little bit because she&#39;s saying stuff like you know losing is an option isn&#39;t an option when you&#39;re a winner like all kinds of weird shit and she&#39;s like, like really winging the darts at the dartboard. So I want to take her side and be like, Hey, it&#39;s just darts like chill out.</p>
<p>Dave! (01:01:40.918)
And even Alba&#39;s just like, I don&#39;t know about this.</p>
<p>Bryan! (01:02:00.212)
But yeah, like this is, we&#39;re supposed to be getting the vibe. Like she&#39;s, she&#39;s not having it. So.</p>
<p>while this is happening and her drink is unattended, Jermaine fixes Anna a drink with a little something extra in it. Yep. And this is the baby and mama have a moment we kind of talked about. We see that her love is fucked up and weird. But you know, she seems to be holding him in place for some grim purpose to keep him pure or whatever. But her love does seem to be real. Meanwhile,</p>
<p>Dave! (01:02:13.482)
And all cars be special.</p>
<p>Bryan! (01:02:36.692)
The effects of whatever is in Anne&#39;s drink take hold. Yeah, and the girls escort her to the basement where she&#39;s gagged and tied up. And it&#39;s a little shadow play to explain what may have happened to the previous caseworker.</p>
<p>Dave! (01:02:40.263)
That&#39;s called Rohypnol.</p>
<p>Bryan! (01:02:54.708)
because uh no they don&#39;t they don&#39;t but like you can you can infer this from what&#39;s happened yeah well also i watched this movie like a bunch of times in relative succession so you know it was all fresh in my mind and i&#39;m like oh shit this is probably what happened to the last uh the last caseworker got a little too close so baby seeing the ladies come out of the basement crawls down finds and they&#39;re</p>
<p>Dave! (01:02:54.742)
but they don&#39;t really explain it. It&#39;s just kind of like, you&#39;ve really got to put a lot of pieces together yourself in this one.</p>
<p>Dave! (01:03:20.438)
Well, you didn&#39;t say why they have to come out of the basement.</p>
<p>Bryan! (01:03:24.956)
well, they Oh, yeah, yeah. He wants Alba to come up and dance with him. And so yeah, the party is still going on. So they all go back upstairs to kind of like deal with that. And they&#39;re tied up. And then baby crawls down and finds her there after a little bit of play helps her get the gag off. And then she freezes.</p>
<p>Dave! (01:03:25.595)
Dennis is being relentless.</p>
<p>Dave! (01:03:46.446)
I mean, not really though, I feel like she could have done this all on her own. She didn&#39;t really need him. Maybe she just needed an audience.</p>
<p>Bryan! (01:03:49.617)
Ha!</p>
<p>Yeah. She frees herself on a saw blade while Alba tortures Dennis the Menace with a lighter and he says, I&#39;d do anything to get to paradise, but does it have to be in an ambulance?</p>
<p>Dave! (01:04:05.886)
And you know, you know that when they wrote that line, they were like, God, that&#39;s good. And I&#39;m going to tell you right now, no, it&#39;s not. It&#39;s really fucking terrible.</p>
<p>Bryan! (01:04:09.428)
That&#39;s a good line. That is a good line. That&#39;s a great line. Shut up. It&#39;s so funny. So now the party&#39;s over. Everybody&#39;s gone. They kick out Dennis the Menace. The ladies conspire to go downstairs and kill Ann, but they find her gone. I know. They do find her just in time to see Ann driving away with baby. They try to chase her, but find that Ann slashed her tires.</p>
<p>Dave! (01:04:28.225)
Gasp! She&#39;s not there.</p>
<p>Bryan! (01:04:38.737)
This is Wadsworth goes, she thinks everything.</p>
<p>Dave! (01:04:41.686)
Lady that is not everything that is one thing she thought of one thing</p>
<p>Bryan! (01:04:49.404)
Yeah, unless she like also, you know, she put sugar in the gas tank.</p>
<p>Dave! (01:04:52.738)
For a second I thought she did and I was like, god damn it, that&#39;s great. But she didn&#39;t, it&#39;s just the tires.</p>
<p>Bryan! (01:04:55.68)
Hahaha</p>
<p>Bryan! (01:05:00.044)
So back at Anne&#39;s, we find out that Mrs. Wadsworth was right. She wanted them all for herself.</p>
<p>and um Mrs. Wadsworth meanwhile, so like what&#39;s happening is uh and is like giving him a bath and she&#39;s like you&#39;re mine now and I&#39;m gonna love you, take care of you.</p>
<p>Dave! (01:05:24.19)
Now, I should say, until tonight, I had not seen this movie in a couple of years. And this is when it started to sink in. And I thought, oh my God, is she gonna fuck this guy? Is that what this is about?</p>
<p>Bryan! (01:05:36.5)
That&#39;s okay. Okay. Because we kind of get around to it. Like, I don&#39;t think that</p>
<p>Dave! (01:05:43.822)
Because I was not ready for that and I&#39;m still not okay with it.</p>
<p>Bryan! (01:05:47.152)
Right. Because like she&#39;s got the... Okay, fuck it. We will sort of explore that when we get there, because that&#39;s like kind of the last note of the movie. So, but yeah, I think the thing is, this kid, this guy just cannot escape that, which is so fucking...</p>
<p>Dave! (01:06:05.81)
He must be very alluring because... ..all ladies coming for him.</p>
<p>Bryan! (01:06:08.732)
I know all these ladies all these ladies love them. So yeah, Mrs. Wadsworth is. Yeah, yeah, for sure like.</p>
<p>Dave! (01:06:13.61)
I mean, it seems like a burden to me, but...</p>
<p>Not to the mother-in-law and Anne. Because now the mother-in-law is fully in on this. And this is like some weird Grey Gardens shit.</p>
<p>Bryan! (01:06:23.324)
Yep. Mrs. Oh, yeah. Yeah, for sure. Mrs. Wadsworth is despondent. And Alba says we should have let that circus take him when they made that offer. Just like you. You think your brother&#39;s a freak?</p>
<p>Dave! (01:06:39.418)
To which I wonder, why would he be in a circus? If anything, he&#39;s just developmentally delayed or disabled, like.</p>
<p>Bryan! (01:06:43.473)
Oh, he&#39;d be a sideshow thing.</p>
<p>Bryan! (01:06:49.148)
Oh, but you can hear the carnival barker just in your mind. You know, come see the man, baby. The man, baby. Alive, alive, alive. The man, baby. Yeah. I mean, by this point in the 70s, I mean, sideshows were probably scraping the barrel, you know, like that seems like it was like a dust bowl thing, you know. But uh yeah, Anne and Judith, her mother-in-law, are now plotting something.</p>
<p>Dave! (01:06:51.954)
I guess.</p>
<p>Dave! (01:06:56.286)
I mean, some people are into that. A lot of money.</p>
<p>Dave! (01:07:07.935)
Yeah.</p>
<p>Dave! (01:07:13.11)
Yeah, because Judith is now fully on board. She goes from being like, well, it&#39;s real sad about what the fuck his name is. And now she&#39;s just like, well, do you think we should kidnap this disabled man and bring him into our house forever? What?</p>
<p>Bryan! (01:07:26.824)
Yeah. Because those two, we&#39;re going to find out they&#39;re on the same page like.</p>
<p>Dave! (01:07:33.494)
Yeah, she gets on board really fucking quick.</p>
<p>Bryan! (01:07:37.468)
Yep. So, the workers are there like working on the pool and they&#39;re ready to lay the pool proper but Judith tells him to hold off for a bit. Judith, Judith asks her if she wants to go through with it, worried that she&#39;ll be just like them and then the two of them proceed to take pictures of baby standing while wearing a suit and then she sends the pictures to the Wadsworths with a taunting letter.</p>
<p>Dave! (01:08:07.426)
And that shit is real cold.</p>
<p>Bryan! (01:08:09.445)
that&#39;s yeah man cuz like her whole thing the whole thing is like the letters like by even if you get him back the transformation will be irreversible baby will be lost to you forever and that she&#39;s she signs it a good samaritan or something like</p>
<p>Dave! (01:08:27.79)
Oh yeah, they&#39;re not gonna think it&#39;s from you, Anne, you idiot.</p>
<p>Bryan! (01:08:30.132)
night. No. So, that night,</p>
<p>Dave! (01:08:32.685)
I don&#39;t think they thought this one through.</p>
<p>Bryan! (01:08:35.604)
Uh no, cuz well, and did as we&#39;re gonna find out, like this is very carefully plotted, like this is some Machiavelli shit.</p>
<p>Dave! (01:08:45.824)
This goes full Last House on the left.</p>
<p>Bryan! (01:08:48.156)
Yeah. Yep. Nobody gets their dick bit off though. Yep. So that night, the Wadsworths go to Anne&#39;s house and then the two girls break in and this is where the movie a movie that&#39;s already taken about a about a dozen crazy turns takes a crazy turn. So,</p>
<p>Dave! (01:08:51.626)
No, but everything else. There&#39;s no chainsaw, but everything else.</p>
<p>Dave! (01:09:06.609)
This is Act 3, everybody.</p>
<p>Dave! (01:09:13.61)
And it has real, real leave something witchy vibes to it. Like it&#39;s just the visuals of it. I was like, ugh, I don&#39;t know if I like this.</p>
<p>Bryan! (01:09:17.924)
Yeah, I also I love yeah, I love but I love this because they use like you could tell the post was like a TV guy because the music as the girls are like slinking around the house and being sneaky is very like hearty boys sneaking around.</p>
<p>Dave! (01:09:35.27)
Oh yeah, no, this is the first time in the music I was like, God, I kind of love this score.</p>
<p>Bryan! (01:09:38.388)
Because also like they&#39;re they keep cutting back and forth because they&#39;ll go back to Ruth in the car. And it&#39;s that like cello motif that they keep playing. And then they go back to the girls and it&#39;s like this muted trumpet that&#39;s like wow wow.</p>
<p>Dave! (01:09:55.614)
Real sexy stuff.</p>
<p>Bryan! (01:09:57.612)
Yeah, Jermaine also brought a gun, by the way, but it&#39;s not gonna do much to help her.</p>
<p>Dave! (01:10:02.326)
But she pulls it out and she looks like she&#39;s surprised that she has it with her.</p>
<p>Bryan! (01:10:06.746)
Yep. Well, she reached into her person was like, Oh shit, that&#39;s right.</p>
<p>Dave! (01:10:09.07)
She reached into her gunny sack dress and... found a gun.</p>
<p>Bryan! (01:10:11.816)
Ha ha ha!</p>
<p>Bryan! (01:10:15.564)
Yeah, but yeah, as soon as she&#39;s in, she&#39;s got the gun out. She hears upset baby sounds coming from upstairs and she follows them in. And then meanwhile, like Alba is also kind of slinking around as well and she eventually comes to the same door and goes in, she finds all of your brain&#39;s stuff there. And then she kind of marches into the gloom and that&#39;s the last look we get at her for a while. Mrs. Wadsworth eventually becomes impatient.</p>
<p>and she also sneaks up in there. And they are not dressed for an infiltration. Like they&#39;re all wearing like skirts and heels and shit. I don&#39;t know, like robber stuff.</p>
<p>Dave! (01:10:50.203)
What do you think they should wear? Are you thinking like that scene in Cry Baby where they go and rescue her from the amusement park at the end and they&#39;re all Dressed in weird black leather? Okay</p>
<p>Bryan! (01:10:56.616)
Hey, yeah, you know, or like sneaking around stuff, you know, a balaclava. Yeah. So she&#39;s wandering around the dark as she enters into the main room, something wet drips onto her face. She looks up and finds germane&#39;s dead body slumped over the railing bleeding from the neck.</p>
<p>Dave! (01:11:03.547)
Wear her sneaking around wig.</p>
<p>Dave! (01:11:18.866)
And I thought, is it really that believable that Anne goes from fucking zero to psychotic killer in what, three weeks?</p>
<p>Bryan! (01:11:25.74)
Oh, this is like jigsaw shit like she. Yeah, she has a murderous resolve like this. This is what she had always planned was we&#39;re going to we&#39;re going to we&#39;re going to infiltrate this family and I&#39;m going to insinuate myself into their lives and I&#39;m going to steal their baby man away. And then I&#39;m going to lure them over here and kill the shit out of them.</p>
<p>Dave! (01:11:48.045)
But also how fortuitous that she just happened upon this situation.</p>
<p>Bryan! (01:11:54.43)
I know, I know.</p>
<p>Dave! (01:11:55.342)
It&#39;s like, well, I have been in the market for a baby man.</p>
<p>Bryan! (01:12:00.812)
Yeah, like she&#39;s looking through all these case files and she&#39;s like, well, how fortunate. No, but then she had to go through, then she had to like mechanically plot this whole thing out and just make sure that everything went according to plan and that she didn&#39;t get removed from the case. And then she could just snatch up the baby man and take him home for herself and then murder her murder his family.</p>
<p>Dave! (01:12:00.926)
And one just fell into my lap.</p>
<p>Dave! (01:12:06.282)
Yeah. I never thought I&#39;d find another one.</p>
<p>Dave! (01:12:28.598)
This- this movie was made by professional people. Heh. It was put into theaters. Uh, maybe. A little bit.</p>
<p>Bryan! (01:12:31.264)
Bravo, it&#39;s such a fucking bulletproof plan.</p>
<p>Bryan! (01:12:39.221)
Yeah. So so seeing germane bleeding, Mrs. Wadsworth rushes upstairs to sort of cradle her in her arms. Just the time for Alba. Yeah, for Alba to stumble out of one of the rooms with a knife in her back.</p>
<p>Dave! (01:12:48.09)
Oh, but there&#39;s more.</p>
<p>Dave! (01:12:53.058)
with the jankiest-looking special effects. It looks like I did it.</p>
<p>Bryan! (01:12:56.676)
Yeah, yeah, yeah. If this were if this were any other movie, it&#39;d be a lot more blood, but it&#39;s a PG rated movie. And this is this is pure EC horror comics like I somebody must have been reading Eerie or Vault of Horror or like, you know, some of those because this definitely has that vibe where it&#39;s a weird story that has a</p>
<p>fucked up twist at the end where like the good the person you thought was the good guy is really the bad guy the whole time and then there&#39;s mayhem and murder.</p>
<p>Dave! (01:13:31.49)
Yeah, but it&#39;s like in those comics, they get their comeuppance. I&#39;m not sure who is supposed to get their comeuppance. I don&#39;t know who the protagonist of this movie is anymore.</p>
<p>Bryan! (01:13:35.316)
That&#39;s usually what it is.</p>
<p>Bryan! (01:13:40.62)
Yeah. Yeah. What the <em>**</em> right? I love it. I love it. Um it takes another crazy unexpected turn because like we just said, Anne has been planning this all along. This was the end goal. It was always to steal a man baby, a baby man, and and then and then murder his family. So</p>
<p>Dave! (01:13:43.294)
My note literally says I&#39;m so confused.</p>
<p>Dave! (01:14:09.578)
What?</p>
<p>Bryan! (01:14:10.48)
Yeah. So so as Mrs. Wadsworth is like Chuck Cradling, her other dying child, and leaps from one of the rooms and fights Mrs. Wadsworth, she&#39;s got a hatchet in her hand. And the two are like chasing each other all around with a hatchet. And then it hits a spot where like you think that Mrs. Wadsworth has the upper hand. But we&#39;ve forgotten. Yes, right. And it looks like she&#39;s going to fucking kill and for a moment there. But we&#39;ve</p>
<p>Dave! (01:14:33.179)
Cause she gets the hatchet.</p>
<p>Dave! (01:14:39.311)
And it&#39;s not alone.</p>
<p>Bryan! (01:14:40.552)
No, we&#39;ve forgotten that Anne&#39;s got help that Mrs. Wadsworth didn&#39;t couldn&#39;t have seen couldn&#39;t have seen coming. Yep, with Chekhov&#39;s meat axe and uh now with two women attacking her, Mrs. Wadsworth leaps over the railing, falling to the floor below, hurting, possibly breaking her legs. And then we go down and rush down.</p>
<p>Dave! (01:14:45.43)
She&#39;s got that bird lady mother-in-law. Creeps out of the fucking shadows like Nosferatu.</p>
<p>Bryan! (01:15:07.14)
And Judith is about to deliver the killing blow. Like she comes like hauling ass around with this thing like Hassan chop over her head. And.</p>
<p>Dave! (01:15:16.804)
I just, when did she get so on board with this? When was she like, oh, you&#39;re gonna kill the family? Okay, cool, I&#39;m in. Sounds good to me.</p>
<p>Bryan! (01:15:19.668)
This, this.</p>
<p>Bryan! (01:15:24.696)
I know like this the condition of her son broke this woman as well as the as an and the two</p>
<p>Dave! (01:15:32.018)
Except they never let that on. Like, you never get that until the end where you&#39;re like, Oh, she- Okay, but that&#39;s not how narrative works. You can&#39;t just drop it on us at the end. That&#39;s shitty writing.</p>
<p>Bryan! (01:15:35.812)
No, that&#39;s the crazy, that&#39;s the fucking twist.</p>
<p>Bryan! (01:15:43.104)
Cause they&#39;ve been, they&#39;ve been, they&#39;ve been hinting at us the whole time. No, no, no. But like that&#39;s, that&#39;s the weird like macabre twist. Like I think it&#39;s so great. Mostly because of how committed to murder she is.</p>
<p>Dave! (01:15:47.559)
Not with her they haven&#39;t.</p>
<p>Dave! (01:15:57.262)
I mean, that&#39;s the same ingrays of this where you&#39;re just like, okay, I guess everybody&#39;s just fucking nuts now. And maybe they always have been, and I just didn&#39;t realize it because who cares?</p>
<p>Bryan! (01:16:02.933)
You</p>
<p>Bryan! (01:16:08.508)
Yep, yep. She&#39;s about to kill her. And Anne stops her and tells her to go get some rope. And as they tie her hands, we can hear Baby upstairs crying. And Mrs. Wadsworth freaks out. And I&#39;m gonna tell ya, I feel f—</p>
<p>Dave! (01:16:20.106)
Yeah, because Anne&#39;s not just psychotic, she&#39;s fucking sadistic now too.</p>
<p>Bryan! (01:16:24.769)
Yes, because there&#39;s a reason she&#39;s not sparing her fucking life. Remember that thing I. Yeah, remember when I said, hey, put a pin in this when they were talking about being buried alive. So.</p>
<p>Dave! (01:16:30.37)
She has a better idea!</p>
<p>Dave! (01:16:37.962)
Now I was very confused at this point because I was like, where are they? Do they have like a tomb of their own somewhere? It&#39;s the swimming pool.</p>
<p>Bryan! (01:16:43.232)
they&#39;re at the they&#39;re at the bottom of the pool. Yep. And so Mrs. Wadsworth is freaking out understandably as she and the two corpses of her daughters are thrown into the hole at the bottom of the pool and Anne buries her alive. Now you would think that that&#39;s the end of the movie, but it but it&#39;s not because the next day it&#39;s the morning and they go and they&#39;ve got baby locked in a closet as one does.</p>
<p>Dave! (01:17:01.714)
Oh no, it gets worse.</p>
<p>Bryan! (01:17:12.028)
and the two women check on each other. They&#39;re like, hey, how you feeling? Feeling great. I&#39;m gonna go make some coffee, she says. Yep. Just totally fine. The two women just, they just murdered some people, buried one of them alive in the backyard. Hey, it&#39;s all good, man. I&#39;m feeling great. How about you?</p>
<p>Dave! (01:17:17.238)
Yeah, well, last night was real crazy, right?</p>
<p>Dave! (01:17:27.839)
Gardens.</p>
<p>Dave! (01:17:32.83)
We did the good thing last night. That was the right thing to do.</p>
<p>Bryan! (01:17:34.312)
Yeah. But now we get to the final plot twist. I did not either. I audibly I was watching this in bed and I audibly sat up and went, what the fuck? Because we go in to she gets baby out of the closet. She takes him into another room where there&#39;s a crib and a playpen and another man, baby. Yeah, it&#39;s her. It&#39;s her husband, Roger.</p>
<p>Dave! (01:17:38.663)
Oh, I didn&#39;t like this.</p>
<p>Dave! (01:18:00.979)
Oh, no.</p>
<p>Oh no.</p>
<p>Bryan! (01:18:05.208)
Yep. Who&#39;s got some kind of like head wrapping, like obviously this car accident that he was in, he hurt himself bad enough to sort of his brain was damaged to sort of reduce him to a grown ass man.</p>
<p>Dave! (01:18:17.49)
Also, is it still like leaking out of his head? Why does he still have bandages on at this point?</p>
<p>Bryan! (01:18:23.08)
I know, and also when they, in the next scene, they put like a swimming cap over it, like gotta keep it dry.</p>
<p>Dave! (01:18:29.546)
No, but before you get to the next scene, this is the big reveal, and then she says, mommy loves her big baby husband. And I was so upset by that line. That is right up there with Susan Tyrrell saying, I&#39;m your girlfriend now.</p>
<p>Bryan! (01:18:32.758)
This.</p>
<p>Bryan! (01:18:38.572)
Hehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehehe</p>
<p>Bryan! (01:18:50.572)
I&#39;m your girlfriend now.</p>
<p>Dave! (01:18:54.174)
And then my note actually says, in bold, holy fucking shit.</p>
<p>And then the note after it says, Oh my god, is she gonna fuck both these guys now?</p>
<p>Bryan! (01:19:07.272)
Yeah, because so now we find out that this conspiracy wasn&#39;t just to steal baby. It was to steal baby so that her baby husband has a playmate.</p>
<p>Dave! (01:19:18.862)
J-You just said baby husband. That&#39;s... .. Big baby husband. Oh, God, it&#39;s gross.</p>
<p>Bryan! (01:19:21.78)
Baby husband. I&#39;m going to say that a lot from now on. Big baby husband. That&#39;s when we get some merch, we&#39;re getting a shirt that says big baby husband right after right next to borscht belt creep. So so also she presents them with gifts. They&#39;re pool toys.</p>
<p>Dave! (01:19:32.566)
Oh! Oh, Jesus Christ! Oh! Oh, God!</p>
<p>Dave! (01:19:47.19)
Which are just the ugliest, worst looking pool toys I&#39;ve ever seen.</p>
<p>Bryan! (01:19:50.196)
They really they went to the five and dime and like got some fucking pool times because they had a five and dime back then for sure.</p>
<p>Dave! (01:19:55.178)
Because everybody&#39;s like the 70s were so fun and kicky. No, this is what they looked like. Everyone had a big baby husband and wore ugly clothes and had terrible pool toys.</p>
<p>Bryan! (01:20:04.54)
Oh yeah, based entirely on this, the 80s was inevitable.</p>
<p>Dave! (01:20:08.638)
Ugh. It was just as bad, everybody. It was just as bad. We all looked ugly. Nobody was happy. Ronald Reagan was president. It&#39;s all because of this.</p>
<p>Bryan! (01:20:15.968)
Nope.</p>
<p>Bryan! (01:20:20.54)
Yep. For Ted posts. God damn you. So now with the pool finished, the three of them can play in it. And while Judith sits to the side happily smoking away, watching them frolic in the pool above the dead bodies of babies. He, uh, Anne is now in like a cute little bikini and she jumps in and frolics very happily with them.</p>
<p>Dave! (01:20:23.402)
Jesus Christ!</p>
<p>Bryan! (01:20:47.932)
And then we freeze frame on what appears to be Anne looking directly down the camera with a look of pure delight. Lady. Yep. Ladies and gentlemen, the baby. Yep. I, I regret nothing.</p>
<p>Dave! (01:20:55.419)
This is the most macabre fucking movie.</p>
<p>Dave! (01:21:00.588)
Oh, God.</p>
<p>Bryan! (01:21:06.812)
Yeah, I this is a movie. This is there&#39;s a few movies that I really wish that I could go back and see again for the first time and this is this is one of them because like that reveal at the end, it&#39;s ridiculous and it&#39;s stupid, but it&#39;s so fucking unexpected. That&#39;s the thing. This movie is unpredictable from moment to moment. There are times when it feels a little dangerous like you just</p>
<p>Dave! (01:21:08.299)
Yeah.</p>
<p>Bryan! (01:21:35.628)
can&#39;t see where it&#39;s going ever. There&#39;s no way to look around the corner. And if you&#39;re paying attention and you&#39;re playing along and you&#39;re trying to sort of plot this out in your head, you&#39;re never going to figure it out. Like you just cannot see where any of this is going or what it&#39;s going to be.</p>
<p>Dave! (01:21:50.651)
But if you look at a comparable movie, if you think about something like Sleepaway Camp, which is another one of those like, wait, do you get to the shocking twist? Whatever. That movie is kind of tongue in cheek. It knows what it&#39;s doing. It&#39;s a little bit self-aware. You know what it&#39;s doing. This movie, they play this movie so fucking straight. We&#39;re just like, this is a weird melodrama. Okay, now there&#39;s a weird drug party,</p>
<p>Wait, did she just say big baby husband?</p>
<p>Like it just you don&#39;t expect it to go where it goes because that&#39;s not how stories.</p>
<p>Bryan! (01:22:19.642)
Yeah.</p>
<p>Bryan! (01:22:26.28)
Yeah. See, I think personally, I think this movie is in spite of all of the nonsense and the weirdness. I think it&#39;s very coherent. Like it&#39;s ridiculous.</p>
<p>Dave! (01:22:40.17)
No, this could be a good movie. It&#39;s just badly written. It&#39;s a badly written film. It&#39;s not the narrative structure is a little all over the place. It&#39;s not very well thought out. It&#39;s very clearly exploitation. It could be a good movie. It actually is a good movie. It&#39;s not it&#39;s a it&#39;s an easy movie to watch.</p>
<p>Bryan! (01:22:42.96)
Yeah. It&#39;s.</p>
<p>Bryan! (01:22:55.676)
It&#39;s also, but it&#39;s also tight.</p>
<p>Bryan! (01:23:00.7)
it&#39;s tight and like at no point there&#39;s sometimes like I&#39;ll be watching movies and I&#39;ll see like how far along are we in this?</p>
<p>Dave! (01:23:07.126)
Yeah, next week&#39;s movie, I&#39;ll tell you, I got to the end of that one, was like, Jesus Christ, there&#39;s 20 minutes more.</p>
<p>Bryan! (01:23:12.468)
I&#39;m not going to do that. Yeah. But um this in this one, I don&#39;t, I don&#39;t do this. It&#39;s it&#39;s snappy. It moves along. There&#39;s not a lot of filler. It there&#39;s a lot of</p>
<p>Dave! (01:23:22.658)
There is a lot of confusion. Because it&#39;s like, wait till- Because what they&#39;re doing is they&#39;re building up for all these big, shocking reveals. It&#39;s like, okay, but you gotta give me something a little bit before that, so when I get there, I&#39;m not like, wait, what? Why does she have a tomb? Oh, right, she has a pool.</p>
<p>Bryan! (01:23:38.406)
But</p>
<p>You just freaked out over Big Baby Husband.</p>
<p>Dave! (01:23:44.262)
I because I don&#39;t like the line because I knew that was coming and I just don&#39;t like what she says</p>
<p>Because I don&#39;t like that phrase and I don&#39;t want anyone to ever say it again.</p>
<p>Bryan! (01:23:54.308)
It&#39;s so yeah, but you know what? I&#39;m gonna say it. Yeah, that&#39;s the thing. This movie is crazy unique. I can&#39;t think of anything comparable. It&#39;s it&#39;s something that is very much unto itself. I&#39;m glad it exists. It&#39;s like this. I received this a million years ago when I was still doing cinema suicide and Severn had put it out and they sent me a review copy.</p>
<p>Dave! (01:23:58.576)
Ugh.</p>
<p>Bryan! (01:24:21.068)
And I didn&#39;t I didn&#39;t watch it. Like I read the like the you know, the attraction cop, you know, this the summary in the back. And I was like, this sounds kind of shitty. Like why would I want to spend my time on this? And then like recently, because I like really only just recently in the last couple of years came around to this one.</p>
<p>Dave! (01:24:38.025)
Yeah, that&#39;s probably because I watched it was like holy shit have you seen this fucking movie</p>
<p>Bryan! (01:24:42.128)
It probably was because that&#39;s kind of how a lot of like I&#39;m filling in a lot of like blind spots lately and a lot of it is because you texted me and we&#39;re like, holy shit. I just watched this. Have you seen this and I&#39;m like, no.</p>
<p>Dave! (01:24:52.066)
Yeah, just so everybody knows, I have a boyfriend who generally says, I don&#39;t care what we watch. So I watch a lot of really shitty movies.</p>
<p>Some of them are hilarious.</p>
<p>Bryan! (01:25:02.744)
Let&#39;s see what&#39;s on Tubi. So, yeah, what are we doing next?</p>
<p>Dave! (01:25:08.154)
Well, next week is a special, a special moment, I believe. We have a we are taking a break from our what the fuck or what the fuckery. And we are right. We&#39;re watching. We watched burnt offerings. Which is fantastic. We have a guest. We have the incredible Sam Pancake, who is the host of one of my favorite podcasts. Sam Pancake hosts or Sam Pancake presents the Saturday afternoon movie. Monday afternoon. Cheese.</p>
<p>Bryan! (01:25:16.285)
Mmm.</p>
<p>Bryan! (01:25:21.704)
Yep. And we got a guest. We got a guest.</p>
<p>Bryan! (01:25:34.152)
Monday afternoon movie. Get it right, say it, say it right, say it with pleasure.</p>
<p>Dave! (01:25:38.454)
My favorite. But it is it is one of my favorite podcasts. He was an incredible guest. We have a great time watching this movie. It is a slow burn of a film I think we all remember to being a lot better than it really was.</p>
<p>Bryan! (01:25:54.032)
I still enjoy the hell out of it. Yeah, but you want to I want you to I want to tease the last one in our what the fuck series.</p>
<p>Dave! (01:25:55.122)
And yet, we had a great time.</p>
<p>Dave! (01:26:00.946)
Oh, okay, well that&#39;s, that&#39;s gonna be the week after. And that is going to be the peanut butter solution. It is nothing at all like the baby, except it is deeply weird. It is very Canadian. And it does not make a lot of sense. And it stars one of the kids from Degrassi Jr.</p>
<p>Bryan! (01:26:05.653)
Yes.</p>
<p>Bryan! (01:26:08.968)
Yeah, I know nothing about this movie.</p>
<p>Bryan! (01:26:24.668)
sweet. Alright, that was it. That was the baby. So, we&#39;ll see you in two weeks. Actually, we&#39;ll see you in a week with burnt offerings and then in the week after that with the peanut butter solution.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/burnt-offerings">Next Episode</Link></li>
                        <li><Link to="/episodes/silver-bullet">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)